jQuery(function($){

    /**************************
     * Listar Posts
     *****************/
    var page = 1;
    var slug = $('.list-group-item.active').data('slug');
    var lastUrl = $('.lista-posts').data('last');
   
    var listarPostsAjax = function (page, slug, lastUrl) {
        //alert ('foi');
        $.ajax({
            url:ajaxwp.ajaxurl,
            type:'GET',
            data:{
                action: 'listarPosts',
                page: page,
                slug: slug,
                lastUrl:lastUrl

            },
            beforeSend:function () {
                //console.log('carregando posts');
                $('.progress').removeClass('d-none');
            }
        })
        .done(function(response){
            //console.log('posts carregados');
            $('.progress').addClass('d-none');
            $('#listar-posts').html(response);
        })
        .fail(function(){
            console.log('opss erro a carregar posts!!!');
        });
        console.log( ajaxwp.ajaxurl,lastUrl );
    };
    listarPostsAjax(page, slug,lastUrl);

    //Ação do botão da categoria
    $('.list-group-item').on('click', function () {
       // alert ("teste");
       slug = $(this).data('slug');
       lastUrl = $(this).data('last');
       listarPostsAjax(1, slug, lastUrl);
       $('.list-group-item').removeClass('active');
       $(this).addClass('active');
    });


    //Ação do botão da paginação
    $('body').on('click', '.page-item', function () {
        // alert ("teste");
        page = $(this).find('span').text();
       listarPostsAjax(page, slug,lastUrl);
        $('.page-item').removeClass('active');
        $(this).addClass('active');
 
    });


    /********************************************************************************************************************************************************
     * Lista posts relacionados
     *****************/




    //var page = 1;
    var id = $('.relacionados').data('category-ids');
     // Adicionei o atributo data-post-id para pegar o ID do post atual
    var post_id = $('.relacionados').data('post-id');
    var listarPostsRelacionadosAjax = function (page, id, post_id) {
        //alert ('foi');
        $.ajax({
            url:ajaxwp.ajaxurl,
            type:'GET',
            data:{
                action: 'listarPostsRelacionados',
                page: page,
                id : id,
                post_id: post_id // Parametro para remover o post atual da lista
            },
            beforeSend:function () {
                //console.log('carregando posts');
                $('.progress').removeClass('d-none');
            }
        })
        .done(function(response){
            //console.log('posts carregados');
            $('.progress').addClass('d-none');
            $('#relacionados').html(response);
        })
        .fail(function(){
            console.log('opss erro a carregar posts!!!');
        });
    };
    listarPostsRelacionadosAjax(page, id, post_id);

    //Ação do botão da paginação
    $('body').on('click', '.page-item', function () {
        // alert ("teste");
        page = $(this).find('span').text();
       listarPostsRelacionadosAjax(page, id, post_id);
        $('.page-item').removeClass('active');
        $(this).addClass('active');
 
    });





    /******************************************************************************** */

     /**************************
     * Listar Último post de categoria
     *****************/
    var page = 1;
    var slug = $('.list-group-item.active').data('slug');
    var lastUrl = $('.lista-posts').data('last');
    var listarPostCategoriaAjax = function (page, slug, lastUrl) {
        //alert ('foi');
        $.ajax({
            url:ajaxwp.ajaxurl,
            type:'GET',
            data:{
                action: 'listarPostCategoria',
                page: page,
                slug: slug,
                lastUrl:lastUrl
            },
            beforeSend:function () {
                //console.log('carregando posts');
                $('.progress').removeClass('d-none');
            }
        })
        .done(function(response){
            //console.log('posts carregados');
            $('.progress').addClass('d-none');
            $('#listar-post-categoria').html(response);
        })
        .fail(function(){
            console.log('opss erro a carregar posts!!!');
        });
        console.log( ajaxwp.ajaxurl,lastUrl );
    };
    listarPostCategoriaAjax(page, slug,lastUrl);

    //Ação do botão da categoria
    $('.list-group-item').on('click', function () {
       // alert ("teste");
       slug = $(this).data('slug');
       lastUrl = $(this).data('last');
       listarPostCategoriaAjax(1, slug, lastUrl);
       $('.list-group-item').removeClass('active');
       $(this).addClass('active');
    });


    //Ação do botão da paginação
    $('body').on('click', '.page-item', function () {
        // alert ("teste");
        page = $(this).find('span').text();
        listarPostCategoriaAjax(page, slug,lastUrl);
        $('.page-item').removeClass('active');
        $(this).addClass('active');
 
    });

    

});